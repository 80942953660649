import React from 'react'
import ReactDOM from 'react-dom'
import ClaimScreen from './claim_i_have_tested/screen'

const start = (container)=>{
  
  ReactDOM.render(
    <ClaimScreen/>,
    container
  )
}

export {
  start,
}
